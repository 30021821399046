.baseContainer {
    background-image: 'url(/bg.png)';
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
    border: 1px solid #ccc;
}

.logoImage{
    height: 61px;
    margin: 0 auto;
    display: block;
    opacity: 0.9;
}
